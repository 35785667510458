import { render } from "react-dom";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AuthApp from "./routes/root/AuthApp";
import UserProfileView from "./routes/user/UserProfileView";
import Auction from "./routes/auction/auction";
import reportWebVitals from "./reportWebVitals";
import ObjectDetail from "./routes/auction/object_detail";
import HomeView from "./routes/home/HomeView";
import More from "./routes/more/more";
import LoginView from "./routes/auth/LoginView";
import RegisterView from "./routes/auth/RegisterView";
import AboutUs from "./routes/more/about_us";
import Donation from "./routes/more/donation";
import AuctionSelect from "./routes/auction/auction_select";
import UserBiddingsView from "./routes/user/UserBiddingsView";
import UserNotificationsView from "./routes/user/UserNotificationsView";
import ForgotPasswordView from "./routes/auth/ForgotPasswordView";
import UnauthApp from "./routes/root/UnauthApp";
import AdminObjectsView from "./routes/admin/AdminObjectsView";
import AdminUsersView from "./routes/admin/AdminUsersView";
import ObjectCreateEdit from "./routes/auction/object_create_edit";
import SmsView from "./routes/auth/SmsView";
import AdminSelect from "./routes/admin/AdminSelectView";
import AdminChatView from "./routes/admin/AdminChatView";
import initOneSignal from "./onesignal";
import initFirebase from "./firebase";
import AdminAuktion from "./routes/admin/AdminAuktionView";
import AdminManualBiddingView from "./routes/admin/AdminManualBiddingView";
import AdminUserReservationView from "./routes/admin/AdminUserReservationView";

initOneSignal();
initFirebase();

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route path="/" index element={<Navigate to="/home" />} />
      <Route element={<UnauthApp />}>
        <Route path="home" element={<HomeView />} />
        <Route path="more" element={<More />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="donation" element={<Donation />} />
        <Route path="auktion" element={<AuctionSelect />} />
        <Route path="auktion/still" element={<Auction stillerKauf={true} />} />
        <Route path="auktion/live" element={<Auction stillerKauf={false} />} />
        <Route path="objects/:objectId" element={<ObjectDetail />} />
        <Route path="objects/:objectId/edit" element={<ObjectCreateEdit />} />
        <Route path="sms" element={<SmsView />} />
        <Route path="forgot" element={<ForgotPasswordView />} />
      </Route>
      <Route element={<AuthApp />}>
        <Route path="user" element={<UserProfileView />} />
        <Route path="user/gebote" element={<UserBiddingsView />} />
        <Route path="user/notifications" element={<UserNotificationsView />} />
        <Route path="login" element={<LoginView />} />
        <Route path="admin" element={<AdminSelect />} />
        <Route path="admin/users" element={<AdminUsersView />} />
        <Route path="admin/objects" element={<AdminObjectsView />} />
        <Route
          path="admin/reservations"
          element={<AdminUserReservationView />}
        />
        <Route path="admin/users" element={<AdminSelect />} />
        <Route path="admin/auktion" element={<AdminAuktion />} />
        <Route path="admin/bidding" element={<AdminManualBiddingView />} />
        <Route path="admin/support/:chatId" element={<AdminChatView />} />
        <Route path="objects/new" element={<ObjectCreateEdit />} />
        <Route path="register" element={<RegisterView />} />
      </Route>
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>Etwas ist schief gelaufen!</p>
          </main>
        }
      />
    </Routes>
  </BrowserRouter>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
