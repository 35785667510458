import * as React from "react";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import {
  Card,
  CardActionArea,
  CardMedia,
  Container,
  CssBaseline,
  Grid,
  Toolbar,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import logo from "./images/BST_Logo_25_Jahre_CMYK.jpg";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

function ElevationScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function Header(props: Props) {
  let navigate = useNavigate();
  const auth = getAuth();
  const [loggedIn, setLoggedIn] = useState(false);
  const handleLogout = () => {
    sessionStorage.removeItem("Auth Token");
    auth.signOut();
    navigate("/home");
  };
  const handleLogin = () => {
    navigate("/login");
  };

  onAuthStateChanged(auth, (user) => {
    setLoggedIn(user != null);
  });

  return (
    <Container>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar style={{ background: "#ffffff" }}>
          <Toolbar>
            <Grid container alignItems="center">
              <Grid item xs={3} display="flex" justifyContent="flex-start">
                {/*<img src={logo} alt="Buergerstiftung Logo" height={50} onClick={navigate('/home')}/>*/}
                <Card sx={{ maxHeight: 70 }}>
                  <CardActionArea component={Link} to="/home">
                    <CardMedia
                      component="img"
                      height="70"
                      image={logo}
                      alt="Buergerstiftung Logo"
                    />
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={6} justifyContent="center">
                <Typography
                  variant="h6"
                  component="div"
                  style={{ color: "#000000" }}
                  align="center"
                >
                  {props.name}
                </Typography>
              </Grid>
              <Grid item xs={3} display="flex" justifyContent="flex-end">
                {loggedIn ? (
                  <Button
                    variant="text"
                    startIcon={<LogoutIcon />}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    startIcon={<LoginIcon />}
                    onClick={handleLogin}
                  >
                    Login/ <br />
                    Regis-
                    <br />
                    trierung
                  </Button>
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </Container>
  );
}
