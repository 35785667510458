import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  CssBaseline,
  Dialog,
  DialogContent,
  Grid,
  List,
  ListItem,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Header from "../../header";
import * as React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import buwogLogo from "../../images/logo_BUWOG.jpg";
import ecoLogo from "../../images/logo_ecobuilding.jpg";
import memoristLogo from "../../images/logo_memorist.png";
import mereteLogo from "../../images/logo_merete.jpg";
import weccLogo from "../../images/logo_WECC.png";
import printLogo from "../../images/logo_printjob.jpg";
import mercedesLogo from "../../images/logo_mercedes.jpg";
import vbLogo from "../../images/logo_volksbank.jpg";
import wbLogo from "../../images/logo_Weberbank.jpg";
import rentLogo from "../../images/logo_rentitnow.jpg";
import {
  buwogText,
  ecoText,
  memoristText,
  mercedesText,
  mereteText,
  printjobText,
  vbText,
  wbText,
  weccText,
} from "./texte";
import parse from "html-react-parser";
import { Breathing, Image } from "react-shimmer";
import { theme } from "../../Theme";

export default function More() {
  const [open, setOpen] = React.useState(false);
  const [dialogText, setDialogText] = React.useState(vbText);
  const [dialogLogo, setDialogLogo] = React.useState(vbLogo);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (text, logo) => {
    setDialogText(text);
    setDialogLogo(logo);
    setOpen(true);
  };

  function getProgram() {
    return (
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" fontWeight="fontWeightMedium">
            Programm
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ background: "#F1B434" }}>
          <Typography component="span" fontWeight="fontWeightMedium">
            Schirmherr des Art Dinners: Kai Wegner, Regierender Bürgermeister
            von Berlin
          </Typography>
          <Typography>
            <Box component="span" fontWeight="fontWeightMedium">
              18:30 Uhr
            </Box>{" "}
            - Einlass, Empfang und Vorbesichtigung
          </Typography>
          <Typography>
            <Box component="span" fontWeight="fontWeightMedium">
              19:00 Uhr
            </Box>{" "}
            - Begrüßung
          </Typography>
          <Typography>
            <Box component="span" fontWeight="fontWeightMedium">
              19:30 Uhr
            </Box>{" "}
            - Vorspeise
          </Typography>
          <Typography>
            <Box component="span" fontWeight="fontWeightMedium">
              20:00 Uhr
            </Box>{" "}
            - Rede
          </Typography>
          <Typography>
            <Box component="span" fontWeight="fontWeightMedium">
              20:15 Uhr
            </Box>{" "}
            - Hauptgang
          </Typography>
          <ul>
            <li>
              <Typography>Auktion</Typography>
            </li>
            <li>
              <Typography>Kilian Jay von Seldeneck</Typography>
            </li>
            <li>
              <Typography>Ausklang mit DJ</Typography>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
    );
  }

  function getImpressum() {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" fontWeight="fontWeightMedium">
            Impressum
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ background: "#F1B434" }}>
          <h4>Kontakt</h4>
          <p>
            Bürgerstiftung Berlin
            <br />
            (Amtsgericht Berlin-Charlottenburg 3416/558-II.2) <br />
            (Steuernummer: 27/643/040531) <br />
            Schillerstraße 59
            <br />
            10627 Berlin-Charlottenburg
          </p>
          <p>
            <span>
              <a href="mailto:mail@buergerstiftung-berlin.de">
                mail@buergerstiftung-berlin.de
              </a>
            </span>
            <br />
            Fon: 030 - 83 22 81 13
          </p>
          <h4>Vorstand</h4>
          <p>
            Andrea Grebe (Vorsitzende)
            <br />
            Frank Wiethoff (stellv. Vorsitzender)
            <br />
            Helena Stadler
            <br />
            Angelika Schilling
            <br />
            Constanze von Kettler
          </p>
          <h4>Haftungsausschluss</h4>
          <p>
            Wir übernehmen keinerlei Haftung für die Inhalte extern verknüpfter
            Seiten. Für die Inhalte solcher Seiten sind ausschließlich deren
            Betreiber verantwortlich.
          </p>
          <h4>Urheberrecht</h4>
          <p>
            Die Inhalte auf dieser und den übrigen Seiten (einschließlich der
            Texte, Bilder, Graphiken- und Animationsdateien) sowie die
            Gestaltung der Seiten unterliegen dem Urheberrecht der
            Bürgerstiftung Berlin bzw. den namentlich genannten Firmen. Die
            Verbreitung ist nur mit unserer schriftlichen Genehmigung zulässig.
          </p>
          <h4>Design</h4>
          <p>
            Ekaterina Didenko
            <br />
          </p>
          <h4>Umsetzung • Hosting</h4>
          <p>
            Lukas Baronyai
            <br />
          </p>
        </AccordionDetails>
      </Accordion>
    );
  }

  function getDatenschutz() {
    return (
      <>
        <a
          href="https://www.iubenda.com/privacy-policy/21204075"
          rel="noopener noreferrer"
          target="_blank"
          title="Datenschutzerklärung"
        >
          Datenschutzerklärung
        </a>
      </>
    );
  }

  function getAgbs() {
    return (
      <a
        href="https://www.iubenda.com/nutzungsbedingungen/21204075"
        rel="noopener noreferrer"
        target="_blank"
        title="Nutzungsbedingungen"
      >
        Nutzungsbedingungen
      </a>
    );
  }

  function getCookies() {
    return (
      <a
        href="https://www.iubenda.com/privacy-policy/21204075/cookie-policy"
        rel="noopener noreferrer"
        target="_blank"
        title="Cookie-Richtlinie"
      >
        Cookie-Richtlinie
      </a>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Header name={"Über die Veranstaltung"} />
        <Box paddingTop={5}>{getProgram()}</Box>
        <Box paddingTop={5} padding={2}>
          <Typography variant="h6" fontWeight="fontWeightMedium">
            Unsere Sponsoren:
          </Typography>
        </Box>
        <Grid container spacing={10} padding={2}>
          <Grid item xs={6} sm={4}>
            <Box
              fullwidth="true"
              onClick={() => handleClickOpen(vbText, vbLogo)}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box m={"auto"}>
                <Image
                  NativeImgProps={{ width: "80%", height: "30%" }}
                  src={vbLogo}
                  alt="Berliner Volksbank Logo"
                  fallback={<Breathing width="100%" height="100%" />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              fullwidth="true"
              onClick={() => handleClickOpen(mercedesText, mercedesLogo)}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box m={"auto"}>
                <Image
                  NativeImgProps={{ width: "80%", height: "30%" }}
                  src={mercedesLogo}
                  alt="Mercedes Benz Logo"
                  fallback={<Breathing width="100%" height="100%" />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              fullwidth="true"
              onClick={() => handleClickOpen(wbText, wbLogo)}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box m={"auto"}>
                <Image
                  NativeImgProps={{ width: "80%", height: "30%" }}
                  src={wbLogo}
                  alt="Weberbank Logo"
                  fallback={<Breathing width="100%" height="100%" />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              fullwidth="true"
              onClick={() => handleClickOpen(buwogText, buwogLogo)}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box m={"auto"}>
                <Image
                  NativeImgProps={{ width: "80%", height: "30%" }}
                  src={buwogLogo}
                  alt="BUWOG Logo"
                  fallback={<Breathing width="100%" height="100%" />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              fullwidth="true"
              onClick={() => handleClickOpen(ecoText, ecoLogo)}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box m={"auto"}>
                <Image
                  NativeImgProps={{ width: "80%", height: "30%" }}
                  src={ecoLogo}
                  alt="Ecobuilding Logo"
                  fallback={<Breathing width="100%" height="100%" />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              fullwidth="true"
              onClick={() => handleClickOpen(mereteText, mereteLogo)}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box m={"auto"}>
                <Image
                  NativeImgProps={{ width: "80%", height: "30%" }}
                  src={mereteLogo}
                  alt="Merete Logo"
                  fallback={<Breathing width="100%" height="100%" />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              fullwidth="true"
              onClick={() => handleClickOpen(memoristText, memoristLogo)}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box m={"auto"}>
                <Image
                  NativeImgProps={{ width: "80%", height: "30%" }}
                  src={memoristLogo}
                  alt="Memorist Logo"
                  fallback={<Breathing width="100%" height="100%" />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              fullwidth="true"
              onClick={() => handleClickOpen(printjobText, printLogo)}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box m={"auto"}>
                <Image
                  NativeImgProps={{ width: "80%", height: "30%" }}
                  src={printLogo}
                  alt="Printjob Logo"
                  fallback={<Breathing width="100%" height="100%" />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              fullwidth="true"
              onClick={() => handleClickOpen(weccText, weccLogo)}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box m={"auto"}>
                <Image
                  NativeImgProps={{ width: "50%", height: "10%" }}
                  src={weccLogo}
                  alt="WECC Logo"
                  fallback={<Breathing width="100%" height="100%" />}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Box
            fullwidth="true"
            onClick={() => handleClickOpen("", rentLogo)}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box m={"auto"}>
              <Image
                NativeImgProps={{ width: "50%", height: "10%" }}
                src={rentLogo}
                alt="Rentit Now Logo"
                fallback={<Breathing width="100%" height="100%" />}
              />
            </Box>
          </Box>
        </Grid>
        <Box paddingTop={5}>
          {getImpressum()}
          <List>
            <ListItem>{getDatenschutz()}</ListItem>
            <ListItem>{getAgbs()}</ListItem>
            <ListItem>{getCookies()}</ListItem>
          </List>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: { borderRadius: 20 },
          }}
        >
          <DialogContent p={4}>
            <Box
              fullwidth="true"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box m={"auto"}>
                <Image
                  NativeImgProps={{ width: "80%", height: "20%" }}
                  src={dialogLogo}
                  alt="Vitra Logo"
                  fallback={<Breathing width="100%" height="100%" />}
                />
              </Box>
            </Box>
            <Typography>
              <>{parse(dialogText)}</>
            </Typography>
          </DialogContent>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
}
