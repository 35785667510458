import { ArtObject, artObjectConverter } from "../../data/art_object";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Container, Divider, Grid, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { FormInputText } from "../../common/FormInputText";
import { FormInputRadio } from "../../common/FormInputRadio";
import Header from "../../header";
import { FormInputTextMultiline } from "../../common/FormInputTextMultiline";
import { theme } from "../../Theme";
import { FormInputEuro } from "../../common/FormInputEuro";
import { ImageUpload2 } from "../../common/ImageUpload2";
import {
  getDownloadURL,
  getStorage,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import { BallTriangle } from "react-loader-spinner";
import { FormInputNumber } from "../../common/FormInputNumber";

const defaultValues = new ArtObject(
  "id",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  [],
  [],
  "",
  true,
  "",
  1,
  1,
  false
);

export default function ObjectCreateEdit() {
  const [object, setObject] = useState(new ArtObject());
  const [dataLoaded, setDataLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const db = getFirestore();
  const navigate = useNavigate();
  let params = useParams();
  const objectId = params.objectId;

  let image1File = null;
  let image2File = null;
  let logoFile = null;

  const methods = useForm({ defaultValues: object ?? defaultValues });
  const { handleSubmit, reset, control } = methods;

  useEffect(() => {
    if (params.objectId && !dataLoaded) {
      const fetchObject = async () => {
        const docRef = doc(db, "objects", params.objectId).withConverter(
          artObjectConverter
        );
        const docSnap = await getDoc(docRef);
        const fetchedObject = docSnap.data();
        setObject(fetchedObject);
        setDataLoaded(true);
        console.log(fetchedObject);
        reset(fetchedObject);
      };
      fetchObject().catch(console.error);
    }
  }, [params, dataLoaded, db, objectId, params.objectId, reset]);
  const onSubmit = async (data: ArtObject) => {
    setSubmitting(true);
    console.log(image1File);
    const objPath = object.still ? "/still/" : "/live/";
    if (image1File) {
      const objName = "lot" + object.id + "" + ".jpg";
      const metadata = { name: objName };
      data.image1 = await uploadToFirebase(
        image1File,
        objPath + objName,
        metadata
      );
    }
    if (image2File) {
      const objName = "lot" + object.id + "-2" + "jpg";
      const metadata = { name: objName };
      data.image2 = await uploadToFirebase(
        image2File,
        objPath + objName,
        metadata
      );
    } else {
      data.image2 = null;
    }
    if (logoFile) {
      const objName = "lot" + object.id + "-logo" + "jpg";
      const metadata = { name: objName };
      data.logo = await uploadToFirebase(logoFile, objPath + objName, metadata);
    } else {
      data.logo = null;
    }
    console.log(data);
    await saveToFirebase(data);
    setSubmitting(false);
    navigate("/admin");
  };

  const saveToFirebase = async (data: ArtObject) => {
    try {
      const parsed = {
        id: object.id,
        name: object.name,
        subname: object.subname ?? null,
        year: object.year ?? null,
        type: object.type ?? null,
        image1: object.image1,
        image2: object.image2 ?? null,
        logo: object.logo ?? null,
        image1Copyright: object.image1Copyright ?? null,
        image2Copyright: object.image2Copyright ?? null,
        startingPrice: object.startingPrice,
        buyingPrice: object.buyingPrice,
        info: object.info ?? null,
        still: object.still,
        artist: object.artist ?? null,
        amountTotal: object.amountTotal ?? null,
        amountAvailable: object.amountAvailable ?? null,
      };
      console.log(parsed);
      const ref = doc(db, "objects", data.id.toString());
      return setDoc(ref, parsed, { merge: true });
    } catch (e) {
      setSubmitting(false);
      console.log(e);
      throw e;
    }
  };

  const uploadToFirebase = async (file: File, imagePath: string, metadata) => {
    try {
      const storage = getStorage();
      const fullPath = "objects/images" + imagePath;
      console.log(fullPath);
      console.log(storage);
      const ref = storageRef(storage, fullPath);
      const snapshot = await uploadBytes(ref, file, metadata);
      console.log("Uploaded a blob or file!");
      console.log(snapshot);
      return await getDownloadURL(snapshot.ref);
    } catch (e) {
      setSubmitting(false);
      console.log(e);
      throw e;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Header name={object.name} />
        <Grid
          container
          spacing={2}
          sx={{
            paddingTop: 4,
            paddingBottom: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <Typography variant="h6"> Neues Auktionsobjekt </Typography>
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <FormInputText name="id" control={control} label="ID*" />
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <FormInputText
              name="name"
              control={control}
              label="Titel"
              required
            />
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <FormInputText
              name="subname"
              control={control}
              label="Untertitel"
            />
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <FormInputText name="year" control={control} label="Jahr" />
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <FormInputText name="type" control={control} label="Typ" />
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <FormInputText name="artist" control={control} label="Künstler" />
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <Divider variant="middle" />
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <Typography
              variant="body1"
              gutterBottom
              mt={3}
              style={{ fontWeight: "bold" }}
            >
              Bild 1*
            </Typography>
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <Grid container>
              <Grid item p={2} xs={12} sm={6} width={"100%"}>
                <ImageUpload2
                  id="image1"
                  src={object.image1}
                  onChange={(file: File) => {
                    image1File = file;
                    console.log(file);
                  }}
                />
              </Grid>

              <Grid item p={2} xs={12} sm={6} width={"100%"}>
                <FormInputText
                  name="image1Copyright"
                  control={control}
                  label="Bild 1 Copyright"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <Typography
              variant="body1"
              gutterBottom
              mt={3}
              style={{ fontWeight: "bold" }}
            >
              Bild 2
            </Typography>
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <Grid container>
              <Grid item p={2} xs={12} sm={6} width={"100%"}>
                <ImageUpload2
                  id="image2"
                  src={object.image2}
                  onChange={(file: File) => {
                    image2File = file;
                    console.log(file);
                  }}
                />
              </Grid>

              <Grid item p={2} xs={12} sm={6} width={"100%"}>
                <FormInputText
                  name="image2Copyright"
                  control={control}
                  label="Bild 2 Copyright"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <Typography
              variant="body1"
              gutterBottom
              mt={3}
              style={{ fontWeight: "bold" }}
            >
              Logo
            </Typography>
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <Grid container>
              <Grid item p={2} xs={12} sm={6} width={"100%"}>
                <ImageUpload2
                  id="logo"
                  src={object.logo}
                  onChange={(file: File) => {
                    logoFile = file;
                    console.log(file);
                  }}
                />
              </Grid>

              <Grid item p={2} xs={12} sm={6} width={"100%"}>
                <FormInputText
                  name="logoCopyright"
                  control={control}
                  label="Logo Copyright"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <Divider variant="middle" />
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <FormInputTextMultiline
              name="info"
              control={control}
              label="Info"
            />
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <FormInputRadio
              name={"still"}
              control={control}
              label={"Angebotstyp"}
            />
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <Grid container>
              <Grid item p={2} xs={12} sm={6} width={"100%"}>
                <FormInputEuro
                  name="startingPrice"
                  control={control}
                  label="Startgebot"
                  handleChange={(event) => {
                    console.log(event);
                  }}
                />
              </Grid>

              <Grid item p={2} xs={12} sm={6} width={"100%"}>
                <FormInputEuro
                  name="buyingPrice"
                  control={control}
                  label="Kaufpreis"
                  handleChange={(event) => {
                    console.log(event);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <Grid container>
              <Grid item p={2} xs={12} sm={6} width={"100%"}>
                <FormInputNumber
                  name="amountAvailable"
                  control={control}
                  label="Anzahl verfügbar"
                />
              </Grid>

              <Grid item p={2} xs={12} sm={6} width={"100%"}>
                <FormInputNumber
                  name="amountTotal"
                  control={control}
                  label="Anzahl insgesamt"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            {submitting ? (
              <BallTriangle />
            ) : (
              <Button
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                fullWidth
                style={{ borderRadius: 20, background: "#F1B434" }}
              >
                Submit
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
