export class ArtObject {
  constructor(
    id: string,
    name: string,
    subname: string,
    year: string,
    type: string,
    image1: string,
    image2: string,
    logo: string,
    image1Copyright: string,
    image2Copyright: string,
    startingPrice: string,
    buyingPrice: string,
    highestBidding: string,
    highestBidder: string,
    buyerList: string[],
    reservationList: string[],
    info: string,
    still: boolean,
    artist: string,
    amountTotal: number,
    amountAvailable: number,
    deleted?: boolean
  ) {
    this.id = id;
    this.name = name;
    this.subname = subname;
    this.year = year;
    this.type = type;
    this.image1 = image1;
    this.image2 = image2;
    this.logo = logo;
    this.image1Copyright = image1Copyright;
    this.image2Copyright = image2Copyright;
    this.startingPrice = startingPrice;
    this.buyingPrice = buyingPrice;
    this.highestBidding = highestBidding;
    this.highestBidder = highestBidder;
    this.buyerList = buyerList;
    this.reservationList = reservationList;
    this.info = info;
    this.still = still;
    this.artist = artist;
    this.amountTotal = amountTotal;
    this.amountAvailable = amountAvailable;
    this.deleted = deleted;
  }

  toString() {
    return this.name;
  }
}

// Firestore data converter
export const artObjectConverter = {
  toFirestore: (object) => {
    return {
      id: object.id,
      name: object.name,
      subname: object.subname,
      year: object.year,
      type: object.type,
      image1: object.image1,
      image2: object.image2,
      logo: object.logo,
      image1Copyright: object.image1Copyright,
      image2Copyright: object.image2Copyright,
      startingPrice: object.startingPrice,
      buyingPrice: object.buyingPrice,
      highestBidding: object.highestBidding,
      highestBidder: object.highestBidder,
      buyerList: object.buyerList,
      reservationList: object.reservationList,
      info: object.info,
      still: object.still,
      artist: object.artist,
      amountTotal: object.amountTotal,
      amountAvailable: object.amountAvailable,
      deleted: object.deleted ?? false,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new ArtObject(
      data.id,
      data.name,
      data.subname,
      data.year,
      data.type,
      data.image1,
      data.image2,
      data.logo,
      data.image1Copyright,
      data.image2Copyright,
      data.startingPrice,
      data.buyingPrice,
      data.highestBidding,
      data.highestBidder,
      data.buyerList,
      data.reservationList,
      data.info,
      data.still,
      data.artist,
      data.amountTotal,
      data.amountAvailable,
      data.deleted ?? false
    );
  },
};
