import { Box, Container, CssBaseline, ThemeProvider } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import logo from "../../images/BST_Logo_25_Jahre_CMYK.jpg";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { theme } from "../../Theme";

export default function ForgotPasswordView() {
  const [submitted, setSubmitted] = useState(false);

  const auth = getAuth();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const actionCodeSettings = {
      url: "https://auction.artdinner.berlin/login",
    };
    sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(() => {
        console.log("sent email");
        setSubmitted(true);
      })
      .catch((error) => {
        console.log(error);
        const errorCode = error.code;
        if (errorCode === "auth/user-not-found") {
          toast.error("Email Adresse unbekannt");
        }
        if (errorCode === "auth/invalid-email") {
          toast.error("Angegebene Email ist nicht valide");
        } else {
          toast.error("Fehler aufgetreten: " + error.message);
        }
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <ToastContainer />
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="Buergerstiftung Logo" height={100} />
          <Typography component="h1" variant="h5">
            ART DINNER 2022 MIT AUKTION
          </Typography>
          <Typography component="h1" variant="h5" paddingTop={2}>
            Passwort zurücksetzen
          </Typography>
          <Typography paddingTop={2}>
            Geben Sie Ihre E-Mail Addrese Ihres Accounts an, um das Passwort
            zurückzusetzen
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Addresse"
              name="email"
              autoComplete="email"
              autoFocus
            />
            {submitted ? (
              <Typography variant="caption" paddingTop={2}>
                Überprüfen Sie Ihre E-Mails.
              </Typography>
            ) : (
              <></>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, borderRadius: 20 }}
            >
              Link senden
            </Button>
            <Link href="/login" variant="body2">
              {"Login"}
            </Link>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
