export const vbText =
  "Aus Liebe zur Region<br/>" +
  "<br/>" +
  "Wir machen uns stark für Berlin und Brandenburg<br/>" +
  "<br/>" +
  "Die Berliner Volksbank ist eine der größten regionalen Genossenschaftsbank in Deutschland. Wir betreuen Privat- und Firmenkund*innen in Berlin und Brandenburg und stützen uns dabei als Genossenschaftsbank auf den Zusammenhalt von über 223.000 Mitgliedern.<br/>" +
  "<br/>" +
  "Unser Herz schlägt für Berlin und Brandenburg. Daher setzen wir uns tatkräftig für eine lebenswerte Zukunft in der Region ein und fördern zahlreiche regionale Projekte, Initiativen und Einrichtung. Ganz im Sinne der genossenschaftlichen Idee „Was einer alleine nicht schafft, das schaffen viele“. Ein besonderer Aspekt ist dabei unsere Kunst- und Kulturarbeit.<br/>" +
  "<br/>" +
  'Einen Überblick zu unserem gesellschaftlichen Engagement finden Sie <a href="https://www.berliner-volksbank.de/ueber-uns/aus-liebe-zur-region.html)">hier</a>.<br/>' +
  "<br/>" +
  'Aktuelle Informationen zur Kunstsammlung, zu laufenden Ausstellungen und zur Werkstatt für Kreative finden Sie <a href="https://kunstforum.berlin">hier</a>.';

export const mercedesText =
  "Die Mercedes-Benz Niederlassung Berlin steht für Kontinuität und Verlässlichkeit," +
  " aber auch für Innovation und zukunftsweisende Technologien. Seit mehr als " +
  "111 Jahren sorgen wir dafür, dass die Berliner*innen immer mit dem aktuellsten " +
  "Stand der Technik verantwortungsvoll unterwegs sein können. Heute steht u.a. " +
  "eine Vielzahl an vollelektrischen Modellen von Mercedes-EQ für Sie bereit – " +
  "und natürlich die Kompetenz unserer über 1.200 Mitarbeiter*innen, deren Leidenschaft " +
  "der Marke Mercedes-Benz gilt. Ebenso wie Ihnen als unseren Kunden." +
  "<br/>" +
  "<b>Herzlich willkommen in Ihrer Mercedes-Benz Niederlassung Berlin.</b>" +
  "<br/>" +
  "Anbieter: <b>Mercedes-Benz AG, Niederlassung Berlin | 9 x in und um Berlin Salzufer 1, 10587 Berlin</b><br/>" +
  'Telefon 030 3901 2000, <a href="www.mercedes-benz-berlin.de">www.mercedes-benz-berlin.de</a>';

export const wbText =
  "Anspruch verbindet<br/> " +
  "<br/>" +
  "Sie leisten Außergewöhnliches. Und irgendwann wollen Sie zufrieden auf Ihr Lebenswerk zurückblicken. " +
  "Wer dem Leistungsprinzip derart verpflichtet ist, der erwartet auch von seiner Privatbank engagiertes und " +
  "kompetentes Handeln. Mit diesem Anspruch sind Sie bei der Weberbank nicht allein. Unsere Kunden vertrauen darauf, " +
  "dass wir sie bei allen Fragen rund um ihr Vermögen exzellent beraten. Seit Jahren wird die Weberbank von verschiedenen " +
  "Instituten für ihre exzellente Vermögensverwaltung ausgezeichnet und gehört damit zu den besten Privatbanken Deutschlands. " +
  "Darüber hinaus ist uns als Privatbank der Hauptstadt die Verbundenheit mit Berlin und seinen Menschen sehr wichtig, " +
  "was wir durch unser nachhaltiges soziales und bürgerliches Engagement zum Ausdruck bringen. <br/>" +
  "<br/>" +
  "Wir bieten Ihnen ein individuelle Leistung, die höchsten Ansprüchen gerecht wird. Seinen Sie anspruchsvoll. Wir sind es auch. <br/>" +
  "<br/>" +
  '<a href="www.weberbank.de">weberbank.de</a>';

export const buwogText =
  "<b>BUWOG: Glücklich wohnen seit 1951</b><br>" +
  "<br>" +
  "Bei der Schaffung von Wohnraum ist die BUWOG ein Teil der Lösung und als Bauträger ein " +
  "verlässlicher Partner für Branche, Politik und Stadtgesellschaft. Die BUWOG hat " +
  "in Deutschland aktuell eine Development-Pipeline von rund 55.000 Wohnungen in " +
  "Bau und in Planung. Dabei verfolgt die BUWOG eine engagierte Nachhaltigkeitsagenda " +
  "und verfügt über ein Energiemanagement-System nach ISO 50001.<br>" +
  "In Berlin ist die BUWOG größter privater Projektentwickler. Mit unseren nachhaltigen" +
  "Quartiersentwicklungen und qualitätsvollem Neubau setzen wir unser Motto aktiv um: Glücklich" +
  "wohnen. Das gilt auch bei unseren vielfach ausgezeichneten Projekten in Berlin, z. B. das Quartier" +
  "BUWOG SPEICHERBALLETT in Spandau, das Quartier 52° Nord in Grünau oder auch die BUWOG" +
  "HYGGE HÖFE in Kaulsdorf. Dort entstehen 166 Eigentumswohnungen mit 1 bis 5 Zimmern und mit" +
  "einer besonders nachhaltigen Ausrichtung: Der Baustoff Holz steht hier im Mittelpunkt.<br>" +
  "<br>" +
  "Mehr über die Aktivitäten der BUWOG, über Quartiersentwicklung und nachhaltigen Neubau erfahren" +
  'Sie auch im neuen <a href="https://www.buwog.de/podcast)">BUWOG Podcast</a>.<br>' +
  "<br>" +
  "Eine Übersicht unserer Projekte finden Sie auf buwog.de – oder entdecken Sie direkt Ihre passende " +
  'Eigentumswohnung mit unserer praktischen <a href="https://www.buwog.de/immobiliensuche)">Immobiliensuche</a>!';
export const ecoText =
  "<b>ecobuilding AG<b><br>" +
  "<b>Nachhaltigkeit durch Innovation<b><br>" +
  "<br>" +
  "Die ecobuilding AG ist auf Nachhaltigkeit, Digitalisierung und Innovation ausgerichtet " +
  "und geht mit ihren spezialisierten Tochterunternehmen neue Wege. Mit einer " +
  "effizienten Kombination aus Building Information Modeling (BIM), Prefabrication und " +
  "einem individuell geplanten Energiemanagement ermöglichen wir so den Bau" +
  "klimaneutraler Immobilien. Dies ist nahezu einzigartig auf dem deutschen Markt.<br>" +
  "Losgelöst vom Kerngeschäft ist es unser Ziel, einen nachhaltigen und positiven " +
  "Einfluss auf unsere Gesellschaft zu nehmen. Die langfristige Unterstützung karitativer " +
  "Organisationen und Initiativen ist ein unverzichtbarer Teil unserer " +
  "Unternehmenskultur. Wir sind davon überzeugt, dass ein ganzheitlicher Ansatz zur " +
  "sozialen Verantwortung anhaltende positive Veränderungen bewirkt, und sind " +
  "deshalb auch stolzer Sponsor des diesjährigen Art Dinners." +
  "<br>" +
  '<a href="https://www.ecobuilding.ag)">www.ecobuilding.ag</a>';
export const mereteText =
  "Die Merete GmbH, ein inhaberingeführtes Unternehmen aus Berlin, " +
  "repräsentiert Spitzenqualität in der Medizintechnik „Made in Germany“. " +
  "Unsere Expertise liegt in der Herstellung von Implantaten und der " +
  "Entwicklung innovativer Lösungen für die Gelenkchirurgie und " +
  "Kinderorthopädie, die weltweit zu einer langfristigen Verbesserung der " +
  "Mobilität, Gesundheit und Lebensqualität beiträgt. Unsere Heimatstadt " +
  "Berlin liegt uns besonders am Herzen; Deshalb unterstützen wir solche " +
  "wertvollen Initiativen wie das Art Dinner, um lokale Veränderungen " +
  "voranzutreiben und unserer Verpflichtung zu Innovation und sozialer " +
  "Verantwortung gerecht zu werden.";
export const memoristText = "";
export const weccText = "";
export const printjobText = "";
