import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  CssBaseline,
  Divider,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Header from "../../header";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { artObjectConverter } from "../../data/art_object";
import { getAuth } from "firebase/auth";
import { userBiddingConverter } from "../../data/user_bidding";
import { Breathing, Image } from "react-shimmer";
import { Link } from "react-router-dom";
import { theme } from "../../Theme";
import { BallTriangle } from "react-loader-spinner";
import { reservationConverter } from "../../data/art_object_reservation";

export default function UserBiddingsView() {
  const [objects, setObjects] = useState([]);
  const [userBiddings, setUserBiddings] = useState();

  const db = getFirestore();
  const auth = getAuth();
  const userId = auth.currentUser?.uid;

  const [liveBiddings, setLiveBiddings] = useState([]);
  const [reservations, setReservations] = useState([]);

  const fetchBiddings = async (data) => {
    const biddingsSnapshot = await getDocs(
      collection(db, "users", userId, "biddings").withConverter(
        userBiddingConverter
      )
    );
    const userBiddings = biddingsSnapshot.docs.map((doc) => doc.data());
    const sorted = userBiddings.sort((a, b) => {
      return (
        new Date(a.biddingDate).getTime() - new Date(b.biddingDate).getTime()
      );
    });
    const mappedBiddings = sorted.map((bidding) => {
      const object = data.filter(
        (artObject) =>
          artObject.id.toString() === bidding.artObjectId.toString()
      )[0];
      if (object) {
        bidding.artObjectName = object.name;
        bidding.artObjectArtist = object.artist;
        bidding.highestBid = object.highestBidding;
        bidding.highestBidder = object.highestBidder;
        bidding.imageUrl = object.image1;
        bidding.still = object.still;
      }
      return bidding;
    });
    setUserBiddings(mappedBiddings);
    return mappedBiddings;
  };

  const fetchReservations = async () => {
    const resSnapshot = await getDocs(
      query(
        collection(db, "reservations"),
        where("userId", "==", userId)
      ).withConverter(reservationConverter)
    );
    const reservations = resSnapshot.docs.map((doc) => doc.data());
    setReservations(reservations);
  };

  useEffect(() => {
    const fetchObjects = async () => {
      const objectsSnapshot = await getDocs(
        collection(db, "objects").withConverter(artObjectConverter)
      );
      const data = objectsSnapshot.docs.map((doc) => doc.data());
      setObjects(data);
      const mappedBiddings = await fetchBiddings(data);
      const livebid = mappedBiddings.filter((bidding) => !bidding.still);
      setLiveBiddings(livebid);
    };
    fetchObjects().catch(console.error);
    fetchReservations().catch(console.error);
  }, [db, liveBiddings.length, reservations.length, userId]);

  const currencyFormat = (num) => {
    return (
      "€" +
      parseInt(num, 10)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  };

  const formatDate = (date) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(date.seconds * 1000).toLocaleDateString("de", options);
  };

  const formatReservationDate = (date) => {
    return date ? date.format("DD.MM.YYYY HH:mm") : "";
  };

  const buildUserReservation = (reservation) => {
    console.log(reservation);
    console.log(objects);
    const filtered = objects.filter(
      (obj) => obj.id.toString() === reservation.objectId.toString()
    );
    console.log(filtered);
    const artObject = filtered[0];
    console.log(artObject);

    return (
      <Grid item xs={12} key={reservation.id}>
        <Card variant="outlined">
          <CardActionArea
            to={`/objects/${reservation.objectId}`}
            component={Link}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div style={{ height: 100 }}>
                    <Image
                      NativeImgProps={{ height: 100 }}
                      src={artObject.image1}
                      fallback={<Breathing width="100%" height="100%" />}
                    />
                  </div>
                  <Typography variant="caption">
                    {artObject.artist} - {artObject.name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <b>Kaufpreis:</b> {currencyFormat(artObject.buyingPrice)}
                  </Typography>
                  <Typography>
                    <b>Anzahl:</b> {reservation.amount}
                  </Typography>
                  <Typography variant="body2">
                    <b>Datum:</b>{" "}
                    {reservation.fulfilled
                      ? formatReservationDate(reservation.confirmationDate)
                      : formatReservationDate(reservation.reservationDate)}
                  </Typography>
                  <Typography variant="body2">
                    <b>Status:</b>{" "}
                    {reservation.fulfilled
                      ? "Kauf bestätigt"
                      : "Warte auf Kaufbestätigung"}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  };

  function buildUserBiddings(bidding) {
    return (
      <Grid item xs={12} key={bidding.id}>
        <Card variant="outlined">
          <CardActionArea
            to={`/objects/${bidding.artObjectId}`}
            component={Link}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div style={{ height: 100 }}>
                    <Image
                      NativeImgProps={{ height: 100 }}
                      src={bidding.imageUrl}
                      fallback={<Breathing width="100%" height="100%" />}
                    />
                  </div>
                  <Typography variant="caption">
                    {bidding.artObjectArtist} - {bidding.artObjectName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <b>Mein Gebot:</b> {currencyFormat(bidding.bidding)}
                  </Typography>
                  {bidding.still ? (
                    <Typography variant="body2">
                      <b>Aktuelles Gebot:</b>{" "}
                      {currencyFormat(bidding.highestBid)}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  <Typography variant="body2">
                    <b>Datum:</b> {formatDate(bidding.biddingDate)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Header name={"Meine Gebote und Käufe"} />
        <Box paddingTop={5}>
          <Grid
            container
            spacing={4}
            alignItems="center"
            direction="row"
            justifyContent="center"
          >
            {!userBiddings ? (
              <BallTriangle />
            ) : !userBiddings.length ? (
              <Grid item xs={12}>
                <Typography>
                  <b>Noch keine Gebote</b>
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {reservations.length !== 0 ? (
              <Grid item xs={12}>
                <Typography variant="h4">
                  <b>Stiller Kauf:</b>
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
            {objects &&
              reservations &&
              reservations.map((reservation) =>
                buildUserReservation(reservation)
              )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {liveBiddings.length !== 0 ? (
              <Grid item xs={12}>
                <Typography variant="h4">
                  <b>Live Auktion:</b>
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
            {objects &&
              userBiddings &&
              liveBiddings.map((bidding) => buildUserBiddings(bidding))}
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
