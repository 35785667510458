import * as React from "react";
import Container from "@mui/material/Container";
import Header from "../../header";
import CssBaseline from "@mui/material/CssBaseline";
import { capitalize, Grid, ThemeProvider, Typography } from "@mui/material";
import { LinkCard } from "../../common/LinkCard";
import { theme } from "../../Theme";
import { PrimaryLinkCard } from "../../common/PrimaryLinkCard";

export default function HomeView() {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Header />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={{ xs: 2, sm: 4 }}
          sx={{ padding: 4 }}
        >
          <Grid item xs={12}>
            <Typography
              align="center"
              variant="h6"
              style={{ textTransform: "uppercase" }}
            >
              Art Dinner 2024 mit Auktion
            </Typography>
            <Typography
              align="center"
              variant="h6"
              style={{ textTransform: "uppercase" }}
            >
              {capitalize("Bürgerstiftung Berlin")}
            </Typography>
            <Typography align="center" variant="h6">
              26.04.2024
            </Typography>{" "}
          </Grid>

          <Grid item align="center" xs={6}>
            {PrimaryLinkCard(
              "Tickets",
              "https://pretix.eu/ArtDinnerBerlin2024/artdinner24/"
            )}
          </Grid>

          <Grid item align="center" xs={6}>
            {LinkCard("Programm", "/more")}
          </Grid>

          <Grid item align="center" xs={6}>
            {LinkCard("Live Auktion", "/auktion/live")}
          </Grid>

          <Grid item align="center" xs={6}>
            {LinkCard("Über uns", "/about")}
          </Grid>

          <Grid item align="center" xs={6}>
            {LinkCard("Stiller Kauf", "/auktion/still")}
          </Grid>

          <Grid item align="center" xs={6}>
            {LinkCard("Spende", "/donation")}
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
