import {
  Box,
  Container,
  CssBaseline,
  Grid,
  ThemeProvider,
} from "@mui/material";
import Header from "../../header";
import { theme } from "../../Theme";
import Typography from "@mui/material/Typography";
import mercedesLogo from "../../images/logo_mercedes.jpg";
import pateZieser from "../../images/pate_Zieser.jpg";
import pateWeyer from "../../images/pate_Weyermann.jpg";
import pateBusch from "../../images/pate_Buschmann.JPG";
import { Breathing, Image } from "react-shimmer";
import * as React from "react";
import Avatar from "@mui/material/Avatar";

export default function Donation() {
  const items = [
    {
      name: "Petra Zieser",
      img: pateZieser,
      work: "LeseLust",
      cite: "„Seit 3 Jahren besuche ich als Lesepatin einmal in der Woche eine Kreuzberger Kita. Obwohl ich einen vollen Terminkalender habe, hat dieser Termin bei mir höchste Priorität. Zu sehen und zu spüren, wie die Kinder ihre Liebe zu Büchern entdecken und voller Freude in die Welt der Buchstaben eintauchen, ist für mich eine große Erfüllung und - ich bin mir sicher - für jedes einzelne Kind eine echte Bereicherung.”",
      donate: "Für 20 € im Monat schenken Sie ein Buch",
      color: "#FCB845",
    },
    {
      name: "Wolf Weyermann",
      img: pateWeyer,
      work: "Zauberhafte Physik",
      cite: "„Unsere Zauberkoffer wecken mit Schallwellen, Kraft und Licht die Neugier der Kinder. Durch das Mitmachen, Entdecken und Forschen erleben sie, wie aufregend Naturphänomene sein können, während sie spielerisch lernen. Das mitzuerleben, ist eine große Freude für beide Seiten.“",
      donate:
        "Für 50 € im Monat statten Sie ein Team der Zauberhaften Physik mit Material für den Besuch einer Schule aus",
      color: "#BE2D18",
    },
    {
      name: "Dr. Vera Buschmann",
      img: pateBusch,
      work: "Projektinitiatorin und ehrenamtliche Rally-Patin",
      cite: "„Die Berlin-Rallye führt die Kinder auf eine spannende Entdeckungsreise. Sie lernen interaktiv, sich im öffentlichen Nahverkehr zu bewegen und werden nachhaltig in ihrer Mobilität gestärkt.”",
      donate: "Für 100 € im Monat schenken Sie einer Schule eine Berlin-Rallye",
      color: "#F47921",
    },
  ];

  function getPate(i) {
    return (
      <Grid item display="flex" flexDirection="column" xs={12} sm={4}>
        <Box height="100%">
          <Grid container height="100%">
            <Grid item xs={12} height="90%">
              <Box
                height="100%"
                style={{
                  borderWidth: 3,
                  borderRadius: 1,
                  borderStyle: "dashed",
                  borderColor: items[i].color,
                  borderTopColor: items[i].color,
                }}
              >
                <Grid
                  container
                  spacing={2}
                  padding={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={12}>
                    <Avatar
                      alt={items[i].name}
                      src={items[i].img}
                      sx={{ width: 124, height: 124 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {items[i].name}
                    </Typography>
                    <Typography variant="caption">{items[i].work}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                      {items[i].cite}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box padding={2} sx={{ background: items[i].color }}>
                <Typography
                  sx={{ fontWeight: "bold", color: "white" }}
                  variant="body1"
                >
                  {items[i].donate}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Header name="Auktionsspende" />
        <Container
          sx={{
            width: "100%",
            height: "100%",
            marginLeft: "8",
            marginRight: "8",
            padding: "0",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Jetzt Pate werden und gewinnen!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={8}>
                  <Typography sx={{ fontStyle: "italic" }} variant="subtitle1">
                    Übernehmen Sie eine Patenschaft für ein Jahr und gewinnen
                    Sie ein Wochenende mit unserem Partner Mercedes-Benz
                    Niederlassung Berlin.
                  </Typography>
                </Grid>
                <Grid item xs={4} alignItems="right">
                  <Box
                    m={"auto"}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image
                      NativeImgProps={{ width: "50%", height: "50%" }}
                      src={mercedesLogo}
                      alt="Mercedes Benz Logo"
                      fallback={<Breathing width="100%" height="100%" />}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={8}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Unter allen neuen Patenschaften, die über den Abend von
                    Gästen des Art Dinners abgeschlossen wurden, verlosen* wir
                    folgenden Mit jeder Patenschaft unterstützen Sie das
                    Engagement für Kinder, Jugendliche und ältere Menschen in
                    Berlin. Schließen Sie ihre Patenschaft gern auch ohne
                    Teilnahme am Art Dinner ab.
                  </Typography>
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                  <Typography variant="body2">
                    Wertgutschein (80 Euro) für Restaurant- oder Hotelleistungen
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Anreise erfolgt ab Berlin mit einem Fahrzeug aus der
                    aktuellen Produktpalette (je nach Verfügbarkeit) von
                    Mercedes-Benz
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontWeight: "bold" }} variant="body2">
                    Wir wünschen Ihnen viel Erfolg!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Ihr Team der Bürgerstiftung Berlin und Mercedes-Benz
                    Niederlassung Berlin
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontStyle: "italic" }} variant="caption">
                    *Teilnahmeberechtigt sind nur Gäste des Art Dinners
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                padding={2}
                sx={{
                  background: "#FCB845",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }} variant="h5">
                  Gutschein:
                </Typography>
                <Typography
                  paddingTop={1}
                  sx={{ fontWeight: "bold" }}
                  variant="subtitle1"
                >
                  2 Übernachtungen inkl. Frühstück Auswahl aus rund 200 Hotels
                  Für 2 Personen im Doppelzimmer
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} p={0}>
            <Grid container spacing={2}>
              {getPate(0)}
              {getPate(1)}
              {getPate(2)}
            </Grid>
          </Grid>
          <Grid item xs={12} p={0}>
            <Box
              paddingTop={10}
              sx={{
                // position: "absolute",
                // top: "20px",
                left: "0",
                right: "0",
                bottom: "0",
              }}
            >
              <iframe
                style={{
                  overflow: "hidden",
                  display: "block",
                  width: "100%",
                  height: "800px",
                  border: "none",
                }}
                src="./twinkle.html"
                title="twinkle-donation"
              />
            </Box>
          </Grid>
        </Container>
      </Container>
    </ThemeProvider>
  );
}
