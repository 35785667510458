import {
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  ThemeProvider,
} from "@mui/material";
import Header from "../../header";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { artObjectConverter } from "../../data/art_object";
import { BallTriangle } from "react-loader-spinner";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { theme } from "../../Theme";
import { toast } from "react-toastify";

export default function AdminObjectsView() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const fetchObjects = async () => {
    const db = getFirestore();
    const snapshot = await getDocs(
      collection(db, "objects").withConverter(artObjectConverter)
    );
    const data = snapshot.docs
      .map((doc) => doc.data())
      .sort((o1, o2) => (o1.id > o2.id ? 1 : -1));
    setData(data);
  };

  useEffect(() => {
    fetchObjects().catch(console.error);
  }, []);

  if (data.length === 0) {
    return (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <BallTriangle color="#00BFFF" height={80} width={80} />
      </div>
    );
  }

  const handleDeleteClick = async (object) => {
    console.log(`delete ${object.id}`);
    const db = getFirestore();
    await setDoc(doc(db, "objects", object.id), {
      deleted: true,
    });
    toast.info(`${object.name} gelöscht`);
  };
  const handleNewClick = () => {
    navigate(`/objects/new`);
  };
  const handleEditClick = (object) => {
    navigate(`/objects/${object.id}/edit`);
  };

  const columns = [
    {
      name: "ID",
      selector: (object) => object.id,
      reorder: true,
      sortable: true,
      width: "5em",
    },
    {
      name: "Name",
      selector: (object) => object.name,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      name: "Subname",
      selector: (object) => object.subname,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      name: "Typ",
      selector: (object) => (object.still ? "Still" : "Live"),
      reorder: true,
      sortable: true,
      width: "7em",
    },
    {
      name: "Action",
      width: "8em",
      cell: (object) => (
        <>
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => handleEditClick(object)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="warning"
            onClick={handleDeleteClick(object)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Header name={"Objekte Übersicht"} />
        <Grid
          container
          spacing={2}
          sx={{
            paddingTop: 4,
            paddingBottom: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid item>
            <DataTable
              columns={columns}
              data={data}
              highlightOnHover
              pagination
              pointerOnHover
              responsive
              striped
            />
          </Grid>
          <Grid item p={2} xs={12} sm={8}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleNewClick}
              style={{ borderRadius: 20, background: "#F1B434" }}
            >
              Neues Objekt hinzufügen
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
